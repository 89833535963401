import React from "react";
import { Navigate } from "react-router-dom";

function ProtectedRoute({ children }) {
    const isLoggedIn = JSON.parse(sessionStorage.getItem("isLoggedIn"));
    if (!isLoggedIn) {
        return <Navigate to="/" replace />
    }
    return children;
}

export default ProtectedRoute;